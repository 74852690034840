<script setup>
import {
  ref, reactive, onMounted, computed, onBeforeMount, toRaw,
} from 'vue';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { closeHandler } from '@/methods/utils';

import LP from '@/services/axios/loyaltyportal';
import store from '@/store';
import TC_Wrapper from "vue-tag-commander";

const props = defineProps(['burn', 'lastBurn']);
const loading = ref(true);
const gift = ref(null);
const burnGift = ref(null);
const displayCopyInfo = ref(false);
let { burn, lastBurn } = props;
let timeoutID = null;
const error = ref({
  active: false,
  message: 'Un problème est survenu',
});

if (lastBurn) {
  burn = lastBurn;
}

const emit = defineEmits(['close']);

document.body.style.overflow = 'hidden';

onBeforeMount(async () => {
    const wrapper = TC_Wrapper.getInstance();
    let pageName = toRaw(store.state.modalActive.from) + ' / '+burn.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / utiliser ce code'
    if(store.state.modalActive.from === 'boutique cadeau' || toRaw(store.state.modalActive.from) === 'mes cadeaux') {
        pageName = burn.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / utiliser mon code';
    }
    await wrapper.setTcVars({"page_cat1": toRaw(store.state.modalActive.from), "page_name": pageName});
});

function closing() {
  document.body.style.overflow = 'auto';
  emit('close', false);
}

burn.extra_info = {
  ...{
    barcode: {
      value: null,
      format: 'upc',
    },
  },
  ...burn.extra_info,
};

const codeToggle = ref(false);

const activeTab = reactive({
  numerique: true,
  barcode: false,
  qrcode: false,
});

// whether the display type is for pdf or not
const isPdf = computed(() => gift.value?.extra_info?.type_affichage?.find(((type) => type.toLowerCase().includes('pdf'))) !== undefined);

const getBurnGift = async () => {
  await LP.get(`/loyalty_portal/burn/gift/${burn.external_id}/`, {
    params: {
      access_token: localStorage.getItem('accessToken_cultura'),
    },
  }).then((res) => {
    burnGift.value = res;
    return res;
  }).catch((err) => {
    console.error(err);
  });
};

function changeActiveTab(key) {
  const keys = Object.keys(activeTab);
  for (let i = 0; keys.length > i; i += 1) {
    if (keys[i] === key) {
      activeTab[key] = true;
    } else {
      activeTab[keys[i]] = false;
    }
  }
}

const copyToClipboard = async (code) => {
  if (timeoutID) {
    clearTimeout(timeoutID);
  }

  try {
    await navigator.clipboard.writeText(code);
    displayCopyInfo.value = true;
    timeoutID = setTimeout(() => {
      displayCopyInfo.value = false;
    }, 1000);
  } catch {
    console.error('Failed to copy: ', code);
  }
};

function downloadPdf() {
  if (gift.value.pdfData) {
    const url = window.URL.createObjectURL(gift.value.pdfData);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = gift.value.title+".pdf";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}

onMounted(async () => {
  try {
    loading.value = true;

    // get gift infos
    const res = await LP.get(`/loyalty_portal/gift/${burn.gift_id}/`);
    gift.value = res;

    // get pdf or burn-gift depending on gift display
    if (isPdf.value) {
      // get the pdf as blob
      const getPdf = await LP.get(`/loyalty_portal/gift/pdf/${burn.external_id}/`, {
        responseType: 'blob',
        params: {
          access_token: localStorage.getItem('accessToken_cultura'),
        },
      });
      gift.value.pdfData = getPdf;
    } else {
      await getBurnGift();
    }

    // if display is for barcode, initialize barcode otherwise initialize qrcode
    if (gift.value.extra_info?.type_affichage?.find(((type) => type.toLowerCase().includes('code barre'))) && !isPdf.value) {
      JsBarcode('#barcode', burnGift.value.code, {
        displayValue: false,
      });
    } else if (!isPdf.value) {
      QRCode.toCanvas(document.getElementById('qrcode_canvas'), burnGift.value.code, {}, (err) => {
        console.log(err);
      });
    }

    // activate numeric tab only if display is not of pdf type
    activeTab.numerique = !isPdf.value;

    loading.value = false;
  } catch (_) {
    error.value.active = true;
  }
});
</script>

<template>
  <!-- eslint-disable vue/html-self-closing -->
  <div
    class="modalContent"
    @click="closeHandler"
  >
    <div :class="['popin_box', 'popin_utilisationCadeau', (store.state.currentHeight <= 820 && store.state.currentWidth >= 480) ? 'popin_box_small_height': '']">
      <p class="txt1">
        Utilisez votre Cadeau
      </p>
      <h3 class="colorBlueCulturaddict">
        {{ burn.title }}
      </h3>
      <div class="greyBoxCtn">
        <div
          v-if="!loading && gift && !isPdf"
          class="tabsCtn"
        >
          <div
            :class="activeTab.numerique ? 'tab active' : 'tab'"
            @click="changeActiveTab('numerique')"
          >
            Code promo
          </div>
          <div
            v-if="gift?.extra_info?.type_affichage?.find((type => type.toLowerCase().includes('code barre')))"
            :class="activeTab.barcode ? 'tab active' : 'tab'"
            @click="changeActiveTab('barcode')"
          >
            Code-barres
          </div>
          <div
            v-if="gift?.extra_info?.type_affichage?.find((type => type.toLowerCase().includes('qrcode')))"
            :class="activeTab.qrcode ? 'tab active' : 'tab'"
            @click="changeActiveTab('qrcode')"
          >
            QR code
          </div>
        </div>
        <div v-else-if="!loading && isPdf">
          <p class="txt2">
            Obtenez votre Cadeau ci-dessous en PDF :
          </p>
        </div>

        <a
          v-if="!loading && isPdf"
          class="cta cta--tertiary"
          style="margin-bottom: 16px;"
          @click="(e) => {
            e.stopPropagation()
            downloadPdf()
          }"
        >
          Télécharger le PDF
        </a>

        <div v-show="!activeTab.numerique && activeTab.barcode && !loading">
          <canvas
            id="barcode"
            style="width: 100%;"
          ></canvas>
          <p class="barcode_txt">
            {{ burnGift?.code }}
          </p>
        </div>

        <div v-show="!activeTab.numerique && activeTab.qrcode && !loading">
          <canvas id="qrcode_canvas"></canvas>
        </div>

        <form v-show="activeTab.numerique && !activeTab.barcode && !loading && !isPdf">
          <p class="txt2">
            {{ codeToggle ? "Copiez ce code promo et utilisez-le dans votre panier" :"Cliquez ci-dessous pour afficher le code promo :" }}
          </p>
          <input
            v-if="!codeToggle"
            type="password"
            value="........"
            class="code"
            disabled
          >

          <p
            v-else
            class="code"
            @click="copyToClipboard(burnGift.code)"
          >
            <Transition name="scale_up">
              <span
                v-if="displayCopyInfo"
                class="info_copy"
              >
                Code copié!
              </span>
            </Transition>
            {{ burnGift.code }}
            <svg
              style="margin-left: 6px;"
              :width="store.state.isMobile ? '18' : '22'"
              :height="store.state.isMobile ? '18' : '22'"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.63636 13.1212H2.93939C1.8683 13.1212 1 12.2529 1 11.1818V2.93939C1 1.8683 1.8683 1 2.93939 1H11.1818C12.2529 1 13.1212 1.8683 13.1212 2.93939V4.63636M6.81818 17H15.0606C16.1317 17 17 16.1317 17 15.0606V6.81818C17 5.74708 16.1317 4.87879 15.0606 4.87879H6.81818C5.74708 4.87879 4.87879 5.74708 4.87879 6.81818V15.0606C4.87879 16.1317 5.74708 17 6.81818 17Z"
                stroke="#004079"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
            </svg>
          </p>

          <div
            v-show="
              !loading &&
                !isPdf &&
                gift.extra_info.code_pin.includes('oui') && (codeToggle && activeTab.numerique)"
          >
            <p class="pin_txt">
              Votre code pin : <span>{{ burnGift?.pinCode || '????' }}</span>
            </p>
          </div>

          <a
            :style="{
              opacity: codeToggle ? 0.5 : 1,
            }"
            class="cta cta--tertiary"
            @click="codeToggle = true"
          >Afficher le code</a>
        </form>

        <div
          v-show="
            !loading &&
              gift?.extra_info?.type_affichage?.every((type => !type.toLowerCase().includes('pdf'))) &&
              gift.extra_info?.code_pin.includes('oui') && (activeTab.qrcode || activeTab.barcode)"
        >
          <p class="pin_txt">
            Votre code pin : <span>{{ burnGift?.pinCode || '????' }}</span>
          </p>
        </div>

        <div
          v-if="loading && !error.active"
          style="margin-bottom: 16px;"
          class="loading"
        >
          <span class="dot dot1" />
          <span class="dot dot2" />
          <span class="dot dot3" />
        </div>

        <div
          v-else-if="error.active"
          style="margin-bottom: 16px;"
          class="txt1"
        >
          {{ error.message }}
        </div>

        <div class="txt3">
          Valable jusqu'au<br>
          <span class="bold">{{ $filters.dateFormat(burn.validity_end_date) }}</span>
        </div>
      </div>
      <div
        v-if="!loading && !isPdf && gift.extra_info?.url_site_utilisation && gift.extra_info.url_site_utilisation[0].trim().length > 0"
        class="redirectUrl"
      >
        <span class="title">Profitez de votre Cadeau sur :</span><br>
        <a
          class="link"
          :href="gift.extra_info.url_site_utilisation[0]"
          target="_blank"
        > {{ gift.extra_info.url_site_utilisation[0] }}</a><br>
      </div>
      <div
        v-if="!loading"
        class="legal"
      >
        <span class="title">Condition d'utilisation</span><br>
        <template
          v-for="text in gift.extra_info?.conditions_utilisation"
          :key="text"
        >
          {{ text }} <br>
        </template>
      </div>
      <span
        class="closeBtn"
        @click="closing"
      />
    </div>
  </div>
</template>

<style scoped>
.scale_up-enter-active,
.scale_up-leave-active {
  transition: scale 0.1s linear;
}

.scale_up-enter-from,
.scale_up-leave-to {
  transform: scale(0.2);
}

.barcode {
  max-width: 100%;
}

.info_copy {
  border: 1px solid #1C2D55;
  background-color: #FFF;
  color: #1C2D55;
  font-size: 0.7rem;
  z-index: 1;
  position: absolute;
  top: -40px;
  right: 0;

  line-height: 120%;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  border-radius: 4px 4px 4px 4px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

@media screen and (max-width: 479px) {
  .popin_utilisationCadeau {
    display: block;
  }
}

.barcode_txt {
  color:  #004079;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 16px;
}

.pin_txt {
  color: #004079;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 16px;
}

.pin_txt span {
  color: #004079;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

#qrcode_canvas {
  width: 200px !important;
  height: 200px !important;

}

@media screen and (max-width: 479px) {
  #qrcode_canvas {
    height: 162px !important;
    width: 162px !important;
  }
}

</style>
