import LP from '@/services/axios/loyaltyportal';
import store from '@/store';

export default {
  async getGifts(limit = false) {
    /**
     * Get all gifts
     */
    // const config = { params: limit ? { limit } : {} };
    const gifts = await LP.get('loyalty_portal/gift/', { params: { limit: 1000 } });
    // Filter on is_active
    gifts.results = gifts.results.filter((gift) => gift.is_active);

    // Delete Gift where stock = 0 and where end date is passed
    gifts.results = gifts.results.filter((gift) => gift.stock !== 0 && new Date(gift.end_date).toISOString().split('T')[0] >= new Date().toISOString().split('T')[0]);
    if (store.state.loyaltyportal.user.burns.filter((burn) => burn.gift_id === 23 && new Date(burn.created).getFullYear() === new Date().getFullYear()).length >= 2) {
      gifts.results = gifts.results.filter((gift) => gift.id !== 23);
    }
    gifts.results.forEach((gift) => {
      // Delete Gift where profil_capping is reached and user has already burn this gift
      if (gift.extra_info.profil_capping && store.state.loyaltyportal.user.burns.filter((burn) => burn.gift_id === gift.id && new Date(burn.created).getFullYear() === new Date().getFullYear()).length >= gift.extra_info.profil_capping) {
        gifts.results = gifts.results.filter((g) => g.id !== gift.id);
      }
    });

    // Delete Gift where typecontact is not the same as user
    gifts.results = gifts.results.filter((gift) => gift.extra_info.typecontact?.includes(store.state.loyaltyportal.user.extra_info.typecontact[0]?.toLowerCase()));
    gifts.results = gifts.results.filter((gift) => gift.extra_info.don[0]?.toLowerCase() === 'non');
    gifts?.results.sort((a, b) => a.points_to_gift - b.points_to_gift);
    if (limit) {
      gifts.results = gifts.results.slice(0, limit);
    }
    return gifts.results || [];
  },
  async getGiftsHP(limit = false) {
    /**
     * Get all gifts
     */
    // const config = { params: limit ? { limit } : {} };
    const gifts = await LP.get('loyalty_portal/gift/', { params: { limit: 1000 } });
    // Filter on is_active
    gifts.results = gifts.results.filter((gift) => gift.is_active);
    // Delete Gift where stock = 0 and where end date is passed
    gifts.results = gifts.results.filter((gift) => gift.stock !== 0 && new Date(gift.end_date).toISOString().split('T')[0] >= new Date().toISOString().split('T')[0] && new Date(gift.begin_date).toISOString().split('T')[0] <= new Date().toISOString().split('T')[0]);
    if (store.state.loyaltyportal.user.burns.filter((burn) => burn.gift_id === 23 && new Date(burn.created).getFullYear() === new Date().getFullYear()).length >= 2) {
      gifts.results = gifts.results.filter((gift) => gift.id !== 23);
    }
    gifts.results.forEach((gift) => {
      // Delete Gift where profil_capping is reached and user has already burn this gift
      if (gift.extra_info.profil_capping && store.state.loyaltyportal.user.burns.filter((burn) => burn.gift_id === gift.id && new Date(burn.created).getFullYear() === new Date().getFullYear()).length >= gift.extra_info.profil_capping) {
        gifts.results = gifts.results.filter((g) => g.id !== gift.id);
      }
    });
    // Delete Gift where typecontact is not the same as user
    gifts.results = gifts.results.filter((gift) => gift.extra_info.typecontact?.includes(store.state.loyaltyportal.user.extra_info.typecontact[0]?.toLowerCase()));
    // Delete gift where don is yes
    gifts.results = gifts.results.filter((gift) => gift.extra_info.don[0]?.toLowerCase() === 'non');
    // Delete gift where stock is 0
    gifts.results = gifts.results.filter((gift) => gift.stock !== 0);
    // Sort by points_to_gift
    gifts?.results.sort((a, b) => a.points_to_gift - b.points_to_gift);
    if (limit) {
      gifts.results = gifts.results.slice(0, limit);
    }

    return gifts.results || [];
  },
  async getAllGifts() {
    const gifts = await LP.get('loyalty_portal/gift/', { params: { limit: 1000 } });
    return gifts.results || [];
  },
  async getGiftDonation() {
    const gifts = await LP.get('loyalty_portal/gift/', { params: { limit: 1000 } });
    gifts.results = gifts.results.filter((gift) => gift.is_active);
    gifts.results = gifts.results.filter((gift) => gift.stock !== 0 && new Date(gift.end_date).toISOString().split('T')[0] > new Date().toISOString().split('T')[0] && gift.extra_info.don[0]?.toLowerCase() === 'oui');
    return gifts.results || [];
  },
};
